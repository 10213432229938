import React from 'react';
import { useGameContext } from '../../GameProvider';

import './Controls.css';

export function Controls() {
  const {newGame, winner} = useGameContext();
  return <div className="controls-container">
    <button onClick={() => newGame(6)}>New Game</button>
    {winner && <button onClick={() => newGame(6)}>Try 12 Cards</button>}
  </div>;
}
