import React, {useContext, useState, createContext, useMemo, useCallback} from 'react';
import { Card, Cards } from './types';
import {randomImages} from '../../assets/CardImages'
import { shuffle } from 'lodash';

interface GameProviderContext {
  cards: Cards;
  attempts: number;
  bestScore?: number;
  winner: boolean;
  size?: number;
  shuffledCardIndexes: string[],
  newGame: (size: number) => void;
  flipCard: (index: string) => void;
}

const GameContext = createContext<GameProviderContext>({
  cards: {},
  attempts: 0,
  bestScore: undefined,
  winner: false,
  size: 6,
  shuffledCardIndexes: [],
  newGame: () => { throw new Error("newGame not Implemented")},
  flipCard: (index: string) => { throw new Error("newGame not Implemented")}
});


interface ProviderProps {
  children?: React.ReactNode;
}

export function GameProvider({children}: ProviderProps) {
  const [cards, setCards] = useState<Cards>({});
  const [shuffledCardIndexes, setShuffledCardIndexes] = useState<string[]>([]);
  const [attempts, setAttempts] = useState(0);
  const [bestScore, setBestScore] = useState<number>();
  const [winner, setWinner] = useState(false);
  const [size, setSize] = useState<number>();

  const updateCard = useCallback((card: Card) => {
    const updatedCards = cards;
    updatedCards[card.index] = card;
    setCards(updatedCards);
  }, [cards]);

  const checkWin = useCallback(() => {
    const unmatchedCards = Object.values(cards).filter((card) => !card.matched);
    if(unmatchedCards.length === 0){
      setWinner(true);
    }
  }, [cards])

  const checkCards = useCallback(() => {
    setAttempts(attempts + 1);
    const flippedCards = Object.values(cards).filter((card) => card.flipped);
    if(flippedCards.length !== 2) return;

    if(flippedCards[0].name === flippedCards[1].name){
      flippedCards[0].matched = true;
      flippedCards[1].matched = true;
      updateCard(flippedCards[0]);
      updateCard(flippedCards[1]);
      checkWin();
    }
  }, [attempts, updateCard, cards, checkWin]);

  const newGame = useCallback((gameSize: number) => {
    console.log('winner', winner)
    console.log('bestScore', bestScore)
    console.log('attempts', attempts)
    console.log('size', size)
    if(winner && (!bestScore || attempts < bestScore)){
      console.log('winner', attempts);
      localStorage.setItem(`bestScore-${size}`, (attempts).toString());
    }
    setSize(gameSize)
    const newCards: Cards = {};
    const images = randomImages(gameSize);
    const rawBestScore = localStorage.getItem(`bestScore-${gameSize}`);
    rawBestScore ? setBestScore(parseInt(rawBestScore)) : setBestScore(undefined);

    for(let i = 0; i < gameSize; i++){
      const card1:Card = {
        name: `card=${i}`,
        src: images[i],
        index: `cardIndex-${Object.keys(newCards).length}`,
        matched: false,
        flipped: false,
        pair: false,
      }
      const card2:Card = {
        name: `card=${i}`,
        src: images[i],
        index: `cardIndex-${Object.keys(newCards).length + 1}`,
        matched: false,
        flipped: false,
        pair: true,
      }
      newCards[card1.index] = card1;
      newCards[card2.index] = card2;
    }
    setShuffledCardIndexes(shuffle(Object.keys(newCards)));
    setCards(newCards);
    setAttempts(0);
    setWinner(false);
  }, [attempts, bestScore, winner, size]);

  const flipCard = useCallback( (index: string) => {
    if(cards[index].matched) return;
    const cardsToUpdate = cards;
    const flippedCards = Object.values(cards).filter((card) => card.flipped);

    if(flippedCards.length > 1) {
      Object.values(cardsToUpdate).forEach((card)=>{
        card.flipped = false;
      });
    }
    cardsToUpdate[index].flipped = !cardsToUpdate[index].flipped;
    setCards(cardsToUpdate);

    checkCards()
  }, [cards, checkCards]);

  const contextValue = useMemo(()=>({
    cards,
    attempts,
    newGame,
    flipCard,
    winner,
    bestScore,
    shuffledCardIndexes,
    size,
  }), [cards, attempts, newGame, flipCard, bestScore, winner, shuffledCardIndexes, size]);

  return <GameContext.Provider value={contextValue}>
    {children}
  </GameContext.Provider>;
}

export function useGameContext() {
  return useContext(GameContext);
}

