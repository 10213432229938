import React from 'react';
import './App.css';
import { Game } from './components';
import { GameProvider } from './components/Game/GameProvider';

function App() {
  return (
    <GameProvider>
      <Game />
    </GameProvider>
  );
}

export default App;
