import React from 'react';

import welcomeImage from '../../../../assets/welcome.jpeg'

import './Welcome.css';

interface WelcomeProps {
  startGame: () => void;
}

export function Welcome({startGame}: WelcomeProps) {
  return <div className="welcome-body">
      <div className="welcome-container">
        <img src={welcomeImage} alt="Happy Birthday" />
        <button onClick={startGame}>Play</button>
        <p>Happy Birthday Jackson! We can't believe you're already three years old. We can't wait to see you next with and have a late birthday celebration with you. We're looking forward to building some snowmen with you and having a snowball fight! Just like last year we've put some money away for you later and it is now worth $942!</p>
        <p>We hope you enjoy your birthday. We created a matching game for you to play.</p>
        <p>Lots of Love, Tyler and Matt.</p>
      </div>
    </div>;
}
