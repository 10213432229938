import image1 from "./1.jpeg";
import image2 from "./2.jpeg";
import image3 from "./3.jpeg";
import image4 from "./4.jpeg";
import image5 from "./5.jpeg";
import image6 from "./6.jpeg";
import image7 from "./7.jpeg";
import image8 from "./8.jpeg";
import image9 from "./9.jpeg";
import image10 from "./10.jpeg";
import image11 from "./11.jpeg";
import image12 from "./12.jpeg";
import image13 from "./13.jpeg";
import image14 from "./14.jpeg";
import image15 from "./15.jpeg";
import image16 from "./16.jpeg";
import image17 from "./17.jpeg";
import image18 from "./18.jpeg";
import image19 from "./19.jpeg";
import image20 from "./20.jpeg";
import image21 from "./21.jpeg";
import image22 from "./22.jpeg";
import image23 from "./23.jpeg";
import image24 from "./24.jpeg";

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
  image21,
  image22,
  image23,
  image24
]

export const randomImages = (size: number) => {
  const selectedImages: number[] = [];
  for(let i = 0; i < size; i++){
    generateImageIndex(selectedImages, images.length)
  }
  return selectedImages.map((index)=> {
    const img = new Image();
    img.src = images[index];
    return images[index];
  });
}

const generateImageIndex = (selectedImages: number[], size: number) => {
  const randomNumber = Math.floor(Math.random() * size);
  if(!selectedImages.includes(randomNumber)) {
    selectedImages.push(randomNumber);
  }else{
    generateImageIndex(selectedImages, size);
  }
}

