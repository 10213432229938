import React from 'react';
import { useGameContext } from '../../GameProvider';
import { CardView } from '../CardView';

import './Board.css';

export interface BoardProps {
  prop?: string;
}

export function Board({prop = 'default value'}: BoardProps) {
  const {shuffledCardIndexes} = useGameContext();

  const cardsMarkup = shuffledCardIndexes.map((cardIndex) => <CardView key={cardIndex} cardIndex={cardIndex} />)

  return <div className='board'>{cardsMarkup}</div>;
}
